import React, { useEffect, useState } from 'react'
import Sidebaar from '../Components/Sidebar'
import { Col, Form } from "react-bootstrap"
import { acutionlist, getdata, imgurl, post, putdata } from '../Api/Api'
import { Link, useNavigate } from 'react-router-dom'
import smileemoji from "../assets/img/smile-emo.svg";
import logo from "../assets/img/logo.png"
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import axios from 'axios'
import { BaseUrl } from '../Url'
import { toast } from 'react-toastify'
import Loader from '../Components/Loader'
import nodatafound from "../assets/img/nodatafound.png"

import Switch from "react-switch";
import moment from 'moment'

function AllBid() {
    const [page, setpage] = useState("1")
    const [totalpage, settotalpage] = useState("")
    const navigate = useNavigate()
    const [supplierdata, setsupplierdata] = useState("")
    const [search, setsearch] = useState("")
    const [maindata, setmaindata] = useState([])
    const [productdata, setproductdata] = useState([])
    const [product, setproduct] = useState([])

    const [startdata, setstartdate] = useState("")
    const [enddate, setenddate] = useState("")


    const [data, setdata] = useState([])
    let token = localStorage.getItem("token")




    const handlall = async (pageSize) => {
        setloading(true)
        const res = await getdata(`auction?page=${pageSize}&rows=20`, headers)
        if (res.is_success == "1") {
            setloading(false)
            setdata(res.data)
            setmaindata(res.data)
            settotalpage(res.totalpage)
        }
        setpage(pageSize)


    }
    const headers = {
        Authorization: `Bearer ${token}`
    }

    const [checked, setChecked] = useState(false);

    const handleWin = async (e, bidid) => {
        e.preventDefault()
        setloading(true)
        let requestdata = {
            bid_id: bidid
        }
        const res = await putdata(`auction/make-winner`, requestdata, headers)

        if (res.is_success == "1") {

            handlall(page)
            setTimeout(() => {
                setloading(false)
                toast.success(res.message)
            }, 600);
        } else {
            toast.error(res.message)
            setloading(false)
        }
    }
    const handleAccept = async (e, bidid) => {
        e.preventDefault()
        setloading(true)
        let requestdata = {
            bid_id: bidid
        }
        const res = await post(`auction/accept`, requestdata, headers)

        if (res.is_success == "1") {

            handlall(page)
            setTimeout(() => {
                setloading(false)
                toast.success(res.message)
            }, 600);
        } else {
            toast.error(res.message)
            setloading(false)
        }
    }



    const handleCancel = async (e, bidid) => {
        e.preventDefault()
        setloading(true)
        let requestdata = {
            bid_id: bidid
        }
        const res = await post(`auction/cancell-auction`, requestdata, headers)
        if (res.errors) {
            res.errors.map((item) => {
                return toast.error(`${item.msg} in ${item.path}`)
            })
        }
        if (res.is_success == "1") {

            handlall(page)
            setTimeout(() => {
                setloading(false)
                toast.success(res.message)
            }, 600);
        } else {
            toast.error(res.message)
            setloading(false)
        }
    }

    const [loading, setloading] = useState(false)

    useEffect(() => {
        handlall(page)
    }, [])




    // const handleinfo = (e, id) => {
    //     e.preventDefault()
    //     // console.log(id)
    //     navigate(`/allproduct/auction/${id._id}`)
    // }












    const statuses = [

        {
            status: "pending",
            bg: "bg-warning text-white"
        },
        {
            status: "closed",
            bg: "bg-success text-white"
        },
        {
            status: "cancelled",
            bg: "bg-danger text-white"
        },
        {
            status: "new",
            bg: "bg-warning text-white"
        },
        {
            status: "accepted",
            bg: "bg-info text-white"
        },
        {
            status: "completed",
            bg: "bg-success text-white"
        }
    ];

    const handleuser = async () => {
        const res = await getdata(`user`, headers)
        setsupplierdata(res)
    }


    useEffect(() => {
        handleuser()
    }, [])

    // useEffect(() => {
    //     let timeoutId;

    //     // Define a function to perform filtering
    //     const performFiltering = () => {
    //         let filteredData = [];
    //         if (search) {
    //             const searchTermLowerCase = search.toLowerCase(); // Convert search term to lowercase
    //             filteredData = data.filter(item => item?.product_id?.title.toLowerCase().includes(searchTermLowerCase) || item?.product_id?..user?.name?.toLowerCase().includes(searchTermLowerCase));
    //             // console.log(filteredData);
    //             setdata(filteredData)
    //         } else {
    //             setdata(maindata)
    //         }
    //     };

    //     // Debounce logic
    //     const debounceFiltering = () => {
    //         clearTimeout(timeoutId);
    //         timeoutId = setTimeout(performFiltering, 300); // Adjust the delay time as needed
    //     };

    //     // Call debounceFiltering whenever search changes
    //     debounceFiltering();

    //     // Clean up the timeout on component unmount or when search changes
    //     return () => {
    //         clearTimeout(timeoutId);
    //     };
    // }, [search]);

    const handleallproduct = async () => {
        let res = await getdata('product?page=1&rows=100000')
        console.log(res.data)
        setproductdata(res.data)
    }

    useEffect(() => {
        handleallproduct()
    }, [])

    // const handlefilter = async () => {
    //     let res = await getdata(`auction?product_id=${product}&sdate=${startdata}&fdate=${enddate}`)
    //     setdata(res.data)
    //     setpage(res.current_page)
    //     settotalpage(res.totalpage)
    // }


    useEffect(() => {
        if (totalpage == 0) {
            settotalpage(1)
        }
    }, [totalpage])

    const handlefilter = async () => {
        setloading(true)
        let query = [];

        if (product) {
            query.push(`product_id=${product}`);
        }
        if (startdata) {
            query.push(`fdate=${startdata}`);
        }
        if (enddate) {
            query.push(`tdate=${enddate}`);
        }

        // Join the query parameters with '&' and construct the URL
        let queryString = query.length > 0 ? `auction?${query.join('&')}` : 'auction';

        let res = await getdata(queryString);

        if (res.is_success == "1") {
            setdata(res.data);
            setpage(res.current_page);
            settotalpage(res.totalpage);
            setloading(false)
        }

    };







    useEffect(() => {
        if (product) {
            handlefilter()
        }
    }, [product, startdata, enddate])


    const handleclear = (e) => {
        e.preventDefault()
        setproduct("")
        setstartdate("")
        setenddate("")
        setdata(maindata)
    }


    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={
                <>
                    <div className="row mt-1 tableview align displaynonepagination">
                        <div className="col-md-10 mb-3">
                            <div className="row">
                                <div className="col-md-3">
                                    <label htmlFor="">Search by Product</label>
                                    <select name="" value={product} onChange={(e) => setproduct(e.target.value)} className='form-control form-select shadow-none' id="">
                                        <option value="">Select Product </option>
                                        {productdata.map((item) => {
                                            return (
                                                <>
                                                    <option value={item._id}>{item.title}</option>
                                                </>
                                            )
                                        })}
                                    </select>
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="">Start Date</label>
                                    <input type="date" className='form-control shadow-none' value={startdata} onChange={(e) => setstartdate(e.target.value)} />
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="">End Date</label>
                                    <input type="date" className='form-control shadow-none' value={enddate} onChange={(e) => setenddate(e.target.value)} />
                                </div>

                                <div className="col-md-3">
                                    <button className='btn bg-green text-white mt-4' onClick={(e) => handleclear(e)}>Clear All Filter</button>
                                </div>
                                {/* <div className="col-md-4">
                                    <div className="searchbox rounded-pill input-group ">
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                        <input type="search" className='form-control shadow-none rounded-pill ' value={search} onChange={(e) => setsearch(e.target.value)} placeholder='Search by Products And Seller Name' />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-flex justify-content-end align-items-center gap-3 mb-3">
                                <button className='btn bg-dark text-white' disabled={page == 1} onClick={(e) => handlall(parseInt(page) - 1)}><i className="fa-solid fa-angle-left"></i></button>
                                <span>{page} / {totalpage}</span>
                                <button className='btn bg-dark text-white' disabled={page >= totalpage} onClick={(e) => handlall(parseInt(page) + 1)}><i className="fa-solid fa-angle-right"></i></button>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="">
                                {/* <Box >
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        getRowId={getRowId}
                                        pageSize={data.length} // Set the page size to the total number of rows
                                        pagination={false}     // Disable pagination
                                    />
                                </Box> */}
                                <div className="table-responsive tableview customtable">
                                    {data.length > 0 ?
                                        <table className="table">
                                            <thead>
                                                <tr >
                                                    <th> Buyer</th>
                                                    <th>Seller</th>
                                                    <th>Product</th>
                                                    {/* <th>Bid Quantity</th>
                                                    <th>Bid Amount</th>
                                                    <th>Market Value</th> */}
                                                    <th>Bid Amount Detail</th>
                                                    <th>
                                                        Bid Dates
                                                    </th>
                                                    {/* <th>Bid Product Status</th> */}
                                                    <th>Bid Status</th>

                                                    <th>Is Winner</th>

                                                    <th>Action</th>





                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item) => {
                                                    return (
                                                        <>
                                                            <tr>

                                                                <td>
                                                                    <ul className='list-unstyled mb-0 '>
                                                                        <li > <b>Bid Id : </b>{item.bid_order_id}</li>
                                                                        <li> <b>Name : </b>
                                                                            {item?.user_id?.name}</li>

                                                                        <li > <b>Email : </b>{item?.user_id?.email}</li>
                                                                        <li > <b>Mobile : </b>{item?.user_id?.contact?.mobile}</li>
                                                                        <li > <b>Whatsapp : </b>{item?.user_id?.contact?.whatsapp}</li>
                                                                        {/* <li > <b>Userid : </b>{item.user_id?._id}</li> */}

                                                                    </ul>
                                                                </td>
                                                                <td>
                                                                    <ul className='list-unstyled mb-0 '>

                                                                        <li> <b>Name : </b>
                                                                            {item?.product_id?.user_id?.name}</li>

                                                                        <li > <b>Email : </b>{item?.product_id?.user_id?.email}</li>
                                                                        <li > <b>Mobile : </b>{item?.product_id?.user_id?.contact?.mobile}</li>
                                                                        <li > <b>Whatsapp : </b>{item?.product_id?.user_id?.contact?.whatsapp}</li>
                                                                        {/* <li > <b>Userid : </b>{item?.product_id?.user_id?._id}</li> */}

                                                                    </ul>
                                                                </td>
                                                                <td>
                                                                    <ul className='list-unstyled mb-0 '>
                                                                        <li> <b>Product : </b>
                                                                            {item?.product_id?.title}</li>

                                                                        <li > <b>Category : </b>{item?.product_id?.category_id.title}</li>

                                                                    </ul>
                                                                </td>
                                                                {/* <td>{item.quantity?.toFixed(2)}  <span>{item?.product_id?.subcategory.unit}</span></td>
                                                                <td>&#8377; {item.bid_amount?.toFixed(2)} </td>

                                                                <td>&#8377;{(item?.product_id?.quantity * item?.product_id?.unit_cost)?.toFixed(2)} </td> */}
                                                                <td>
                                                                    <ul style={{ listStyle: "none", padding: 0 }}>
                                                                        <li><b>Bid Quantity : </b>{item.quantity?.toFixed(2)}  <span>{item.unit}</span></li>
                                                                        <li><b>Bid Amount :</b> &#8377; {item.bid_amount?.toFixed(2)}</li>
                                                                        <li><b>Market Value :</b> &#8377;{(item?.product_id?.unit_cost)?.toFixed(2)}</li>
                                                                    </ul>
                                                                </td>
                                                                <td>
                                                                    <ul className='list-unstyled mb-0 '>
                                                                        <li><b>Bid Start at</b> : {moment(item?.product_id?.start_at).format("YYYY-MM-DD")}</li>
                                                                        <li><b>Bid End at</b> : {moment(item?.product_id?.end_at).format("YYYY-MM-DD ")}</li>
                                                                        <li><b>Bid Create at</b> : {moment(item.bid_date).format("YYYY-MM-DD ")}</li>
                                                                    </ul>
                                                                </td>
                                                                {/* <td>
                                                                    <span className={`badge px-3 rounded-pill 
                                                                ${statuses.find(obj => obj.status == item?.product_id?.is_completed.toLowerCase())?.bg}`}>
                                                                        {item?.product_id?.is_completed}
                                                                    </span>
                                                                </td> */}
                                                                <td>
                                                                    <span className={`badge px-3 rounded-pill 
                                                                ${statuses.find(obj => obj.status == item.status.toLowerCase())?.bg}`}>
                                                                        {item.status}
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    {item.status == "pending" ? <span className='badge bg-warning text-white px-3 rounded-pill'>Pending</span> : item.is_win ? <span className='badge bg-success text-white px-3 rounded-pill'>Winner</span> : <span className='badge bg-danger text-white px-3 rounded-pill'>No</span>}
                                                                </td>

                                                                <td>
                                                                    {item.status != "completed" && item.status != "closed" ? <div className="dropdown">
                                                                        <button className="btn custombtn dropdown-toggle p-0 px-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            Action
                                                                        </button>
                                                                        <ul className="dropdown-menu">
                                                                            {item.status == "accepted" ? <li><button className="dropdown-item btn" href="#" onClick={(e) => handleWin(e, item._id)}>Make Win</button></li>
                                                                                : item.status == "New" || item.status == "pending" ? <>
                                                                                    <li onClick={(e) => handleAccept(e, item._id)}><button className="dropdown-item btn">Accept Now</button></li>
                                                                                    <li onClick={(e) => handleCancel(e, item._id)}><button className="dropdown-item btn" >Cancel Now</button></li>
                                                                                </> : ""}
                                                                        </ul>
                                                                    </div> : <span className={`badge px-3 rounded-pill bg-success text-white`}>
                                                                        {item.status}
                                                                    </span>}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        : <div className='w-100 text-center mt-5'>
                                            <img src={nodatafound} className='img-fluid rounded-4' style={{ height: "350px" }} alt="" />
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>


                </>} />
        </>
    )
}

export default AllBid






