import React, { useState, useEffect } from "react";

import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "axios"



import logo from "../assets/img/logo.png"
import { ToastContainer } from "react-toastify";
import { BaseUrl } from "../Url";

const Sidebaar = ({ content }) => {
    const [isCollapsed, setIsCollapsed] = useState(
        localStorage.getItem("isCollapsed") === "true"
    );
    const navigate = useNavigate();


    const env = localStorage.getItem("env")

    let token = localStorage.getItem("token")

    const headers = {
        Authorization: `Bearer ${token}`
    }

    const [isChecked, setIsChecked] = useState(false);


    useEffect(() => {
        if (env == "dev") {
            setIsChecked(true)
        }
    }, [env])

    const handleToggle = () => {
        setIsChecked(!isChecked);

        let requestdata = {
            default: !isChecked ? "dev" : "prod"
        }
        axios.post(`${BaseUrl}/admin/update-env`, requestdata, { headers }).then((res) => {
            console.log(res.data.is_success)
            if (res.data.is_success == "1") {
                localStorage.setItem("env", !isChecked ? "dev" : "prod")
            }
        })
    };

    const handleToggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
        localStorage.setItem("isCollapsed", isCollapsed ? "false" : "true");
    };

    useEffect(() => {
        checkCollapse();
    }, []);

    const checkCollapse = () => {
        if (localStorage.getItem("isCollapsed") === "true") {
            setIsCollapsed(true);
        }
    };

    //   useEffect(() => {
    //     if (!localStorage.getItem("token")) {
    //       navigate("/");
    //     }
    //   }, []);

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            // Handle specific error codes or situations here

            if (error.response && error.response.status === 403) {
                // Handle 403 error without logging it
                // navigate("/login");

                navigate("/");
            }

            return Promise.reject(error);
        }
    );

    const handlelogout = () => {
        // Clear cookies
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });

        localStorage.clear();
    };



    return (

        <div className="w-100 d-flex">
            <div className={`sidebarContainer ${isCollapsed ? "collapsed" : ""}`} style={{ background: "#000" }}>
                <div className="w-100 h-100 position-relative">
                    <div className="sidebar-header  flex justify-center">

                        <Link
                            className="btn text-dark bg-white"
                            id="closeSidebar"
                            onClick={handleToggleSidebar}
                        >
                            {isCollapsed ? (
                                <i className="fa-solid fa-xmark fs-5"></i>
                            ) : (
                                <i className="fa-solid fa-bars fs-5"></i>
                            )}
                        </Link>
                        <Link className="d-md-none d-block desktoplogo text-center">
                            <img
                                src={logo}
                                className="img-fluid mobile_fullname_logo"
                                alt=""
                            />
                        </Link>
                        <Link className="d-none d-md-block text-center">
                            {isCollapsed ? (
                                <img
                                    src={logo}
                                    alt=""
                                    className="img-fluid beat_logo mt-0"
                                    style={{ width: "80px" }}
                                />
                            ) : (
                                <img src={logo} alt="" className="img-fluid beat_logo" />
                            )}

                            {/* <img src={logo} className="img-fluid fullname_logo" alt="" /> */}
                        </Link>
                    </div>
                    <div className="sidebar-content" id="Sidebar_nav">
                        <ul className="list-unstyled">

                            <li>
                                <NavLink
                                    to={"/home"}
                                    className="d-flex  align-items-center gap-2 Sidebar_link"
                                >
                                    <span className="icon">
                                        <i className="fa-solid fa-house"></i>
                                    </span>
                                    <p
                                        className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                    >
                                        Dashboard
                                    </p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={"/category"}
                                    className="d-flex  align-items-center gap-2 Sidebar_link"
                                >
                                    <span className="icon">
                                        <i class="fa-solid fa-list"></i>
                                    </span>
                                    <p
                                        className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                    >
                                        Category
                                    </p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={"/enquiry"}
                                    className="d-flex  align-items-center gap-2 Sidebar_link"
                                >
                                    <span className="icon">
                                        <i class="fa-solid fa-circle-info"></i>
                                    </span>
                                    <p
                                        className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                    >
                                        Enquiry
                                    </p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={"/batchlist"}
                                    className="d-flex  align-items-center gap-2 Sidebar_link"
                                >
                                    <span className="icon">
                                        <i class="fa-solid fa-id-badge"></i>
                                    </span>
                                    <p
                                        className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                    >
                                        Batch List
                                    </p>
                                </NavLink>
                            </li>
                            {/* <li>
                                <NavLink
                                    to={"/subcategory"}
                                    className="d-flex  align-items-center gap-2 Sidebar_link"
                                >
                                    <span className="icon">
                                        <i class="fa-solid fa-table-list"></i>
                                    </span>
                                    <p
                                        className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                    >
                                        Sub Category
                                    </p>
                                </NavLink>
                            </li> */}
                            <li>
                                <NavLink
                                    to={"/allproduct"}
                                    className="d-flex  align-items-center gap-2 Sidebar_link"
                                >
                                    <span className="icon">
                                        <i class="fa-solid fa-box-open"></i>
                                    </span>
                                    <p
                                        className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                    >
                                        All product
                                    </p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={"/alluser"}
                                    className="d-flex  align-items-center gap-2 Sidebar_link"
                                >
                                    <span className="icon">
                                        <i className="fa-solid fa-users"></i>
                                    </span>
                                    <p
                                        className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                    >
                                        All User
                                    </p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={"/allbid"}
                                    className="d-flex  align-items-center gap-2 Sidebar_link"
                                >
                                    <span className="icon">
                                        <i class="fa-solid fa-scale-balanced"></i>
                                    </span>
                                    <p
                                        className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                    >
                                        All Bid
                                    </p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={"/policy"}
                                    className="d-flex  align-items-center gap-2 Sidebar_link"
                                >
                                    <span className="icon">
                                        <i class="fa-solid fa-file"></i>
                                    </span>
                                    <p
                                        className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                    >
                                        Policy
                                    </p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={"/contactsocial"}
                                    className="d-flex  align-items-center gap-2 Sidebar_link"
                                >
                                    <span className="icon">
                                        <i class="fa-solid fa-address-card"></i>
                                    </span>
                                    <p
                                        className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                    >
                                        Contact & Social Media
                                    </p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={"/faq"}
                                    className="d-flex  align-items-center gap-2 Sidebar_link"
                                >
                                    <span className="icon">
                                        <i class="fa-regular fa-circle-question"></i>
                                    </span>
                                    <p
                                        className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                    >
                                        Faq
                                    </p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={"/blog"}
                                    className="d-flex  align-items-center gap-2 Sidebar_link"
                                >
                                    <span className="icon">
                                        <i class="fa-brands fa-blogger-b"></i>
                                    </span>
                                    <p
                                        className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                    >
                                        Blogs
                                    </p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={"/notification"}
                                    className="d-flex  align-items-center gap-2 Sidebar_link"
                                >
                                    <span className="icon">
                                        <i class="fa-solid fa-box-open"></i>
                                    </span>
                                    <p
                                        className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                    >
                                        Push Notifications
                                    </p>
                                </NavLink>
                            </li>
                            <li className="w-100">
                                <NavLink
                                    onClick={handlelogout}
                                    to={'/'}
                                    className="d-flex  align-items-center gap-2 Sidebar_link"
                                >
                                    <span className="icon">
                                        <i className="fa-solid fa-power-off"></i>
                                    </span>
                                    <p
                                        className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                    >
                                        Logout
                                    </p>
                                </NavLink>
                            </li>

                        </ul>

                    </div>
                    <div className="sidebar-footer ">{/* Sidebar footer */}</div>
                </div>
            </div>
            <div className={`mainContainer  ${isCollapsed ? "collapsed" : ""}`}>
                <ToastContainer />
                <div className="w-100 h-100">
                    <div className="w-100 sticky-top">
                        <nav className="w-100 topbarNav nav-bg">
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col-md-12 col-4">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Link
                                                className="btn border-0 shadow-none makeExtend bg-white mt-2"
                                                onClick={handleToggleSidebar}
                                            >
                                                {isCollapsed ? (
                                                    <i className="fa-solid fa-xmark fs-5"></i>
                                                ) : (
                                                    <i className="fa-solid fa-bars fs-5"></i>
                                                )}
                                            </Link>
                                            <div className="d-none" style={{ position: "relative", right: "16%", top: "12px" }}>
                                                <div className="switch-container">
                                                    <form action="" >
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={isChecked}

                                                                onChange={handleToggle}
                                                            />
                                                            <span className="slider"></span>
                                                        </label>
                                                        <span className="label-text">
                                                            {isChecked ? 'Development Mode' : 'Live'}
                                                        </span>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mobilelogo">
                                            <Link className="d-md-none d-block">
                                                <img
                                                    src={logo}
                                                    className="img-fluid mobile_fullname_logo"
                                                    alt=""
                                                />
                                            </Link>
                                            <Link className="d-none d-md-block">
                                                <img
                                                    src={logo}
                                                    alt=""
                                                    className="img-fluid beat_logo"
                                                />
                                                <img
                                                    src={logo}
                                                    className="img-fluid fullname_logo"
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className="container-fluid" style={{ marginTop: "90px" }}>
                            <div className="row gy-3 mt-3">{content}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebaar;
